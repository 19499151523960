<template>
  <div class="container mb-5">
    <nav class="row">
      <div class="appbar col-md-12 col-sm-12 mb-4">
        <div class="d-flex align-items-center">
          <router-link
            :to="'/hasil'"
            class="btn-back pe-3 text-decoration-none pt-2 text-gray-1"
          >
            <i class="ri-arrow-left-line ri-xl fw-500"></i>
          </router-link>

          <h1 class="fw-600 fs-14 lh-28 mb-0">Detail Hasil</h1>

          <button
            class="btn btn-search lh-0"
            data-bs-toggle="modal"
            data-bs-target="#modalDownload"
            @click="onHandleDownload">
            <i class="ri-download-2-fill ri-xl fw-500"></i>
          </button>
        </div>
      </div>
    </nav>
    <!-- divider 😂 -->
    <div class="row mt-5 mt-md-0 pt-2 pt-md-0"></div>

    <div class="row tabs">
      <div
        class="col p-2 pt-0 text-center"
        :class="{ 'active': tabActive === 1 }"
        @click="tabActive = 1"
      >
        <span>INFORMASI</span>
      </div>

      <div
        class="col p-2 pt-0 text-center"
        :class="{ 'active': tabActive === 2 }"
        @click="tabActive = 2"
      >
        <span>HASIL</span>
      </div>

      <div
        class="col p-2 pt-0 text-center"
        :class="{ 'active': tabActive === 3 }"
        @click="tabActive = 3"
      >
        <span>LAMPIRAN</span>
      </div>
    </div>

    <!-- Informasi -->
    <div v-if="tabActive === 1" class="mt-2 row">
      <Loading v-if="loading.hasil" />
      <Empty
        v-else-if="!detailHasil.biodata"
        :message="'Saat ini belum ada data Informasi untuk ditampilkan.'"
      />
      <div v-else>
        <div class="col-12 col-md-12 px-0 mb-2">
          <div class="card card-patient radius-12 bg-info-500 border-0">
            <div class="card-body d-flex flex-column">
              <div class="radius-12 d-flex align-items-center gap-2 px-2 py-3 bg-white">
                <img src="@/assets/icon/doctor.svg" alt="Dokter" />
                <div class="description d-flex flex-column">
                  <h6 class="fs-12 lh-14 fw-400 mb-1">
                    Dokter Penanggung Jawab
                  </h6>
                  <div>
                    <span class="fs-14 lh-20 fw-700">{{ detailHasil.biodata.dokterpj }}</span>
                  </div>
                </div>
              </div>
              <div class="patient-info d-flex align-items-center gap-2 py-3">
                <img src="@/assets/icon/profile/data-diri.svg" alt="Foto Anda" class="foto-selfie"/>
                <div class="description d-flex flex-column text-white">
                  <h6 class="fs-12 lh-14 fw-400 mb-1">Nama Pasien</h6>
                  <div>
                    <span class="fs-14 lh-20 fw-700">{{ detailHasil.biodata.nama_pasien }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-striped">
          <tbody>
            <tr>
              <td class="fs-12" style="width: 115px;">Tanggal Periksa</td>
              <td>{{ detailHasil.biodata.tglperiksa }}</td>
            </tr>
            <tr>
              <td class="fs-12" style="width: 115px;">Tanggal Validasi</td>
              <td>{{ detailHasil.biodata.tglvalidasi }}</td>
            </tr>
            <tr>
              <td class="fs-12" style="width: 115px;">No Laboratorium</td>
              <td>{{ detailHasil.biodata.no_lab }}</td>
            </tr>
            <tr>
              <td class="fs-12" style="width: 115px;">Nama Cabang</td>
              <td>{{ detailHasil.biodata.nama_cabang }}</td>
            </tr>
            <tr>
              <td class="fs-12" style="width: 115px;">Alamat Cabang</td>
              <td>{{ detailHasil.biodata.alamat_cabang }}</td>
            </tr>
            <tr>
              <td class="fs-12" style="width: 115px;">Telp. Cabang</td>
              <td>{{ detailHasil.biodata.notelp_cabang }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Hasil -->
    <div v-if="tabActive === 2" class="mt-2 row">
      <Loading v-if="loading.hasil" />
      <Empty
        v-else-if="!detailHasil.hasil || detailHasil.hasil.length === 0"
        :message="'Saat ini belum ada data Hasil untuk ditampilkan.'"
      />
      <table v-else class="table table-striped">
        <thead class="bg-primary text-white">
            <tr class="text-center">
                <th scope="col" width="40%">Pemeriksaan</th>
                <th scope="col" width="20%">Hasil</th>
                <th scope="col">Rujukan</th>
            </tr>
        </thead>
        <tbody>
          <tr
            v-for="hasil in detailHasil.hasil"
            :key="hasil.hasil"
          >
            <td>
                <span v-if="hasil.no" class="fw-bold text-primary">{{ hasil.no }}. {{ hasil.nama_pemeriksaan }}</span>
                <span v-else>{{ hasil.nama_pemeriksaan }}</span>
            </td>
            <td class="text-center">{{ hasil.hasil }}</td>
            <td>{{ hasil.nilai_rujukan }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Lampiran -->
    <div v-if="tabActive === 3" class="mt-2">
      <Loading v-if="loading.lampiran || loading.lampiranMcu" />
      <Empty
        v-else-if="listLampiran.length === 0 && listLampiranMcu.length === 0"
        :message="'Saat ini belum ada data Lampiran untuk ditampilkan.'"
      />

      <section v-else>
        <div class="accordion mt-2" id="accordionLampiran">

          <!-- Bagian lampiran pemeriksaan mcu -->
          <div v-if="listLampiranMcu.length > 0" class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button mcu" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMcu">
                Hasil Medical Check Up
              </button>
            </h2>
            <div id="collapseMcu" class="accordion-collapse collapse show" data-bs-parent="#accordionLampiran">
              <div class="accordion-body">
                <ul class="clean">
                  <li v-for="mcu in listLampiranMcu" :key="mcu.judul">
                    {{ mcu.judul }}
                    <ul v-if="Array.isArray(mcu.detail)">
                      <li v-for="detail in mcu.detail" :key="detail.no">
                        {{ detail.no }} {{ detail.group }}
                        <ul>
                          <li v-for="detailDetail in detail.detail" :key="detailDetail">
                            {{ detailDetail }}
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <ul v-else>
                      <li>
                        {{ mcu.detail }}
                      </li>
                    </ul>
                  </li>

                  <li v-if="pdfMcu">
                    <caption class="d-block p-0 mt-3">
                      <button @click="downloadHasilScan(pdfMcu)" class="caption btn btn-primary">
                        Download Lampiran
                      </button>
                    </caption>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Bagian lampiran pemeriksaan pribadi -->
          <div v-for="lampiran in listLampiran" :key="lampiran.group" class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                :class="{'collapsed': listLampiranMcu.length > 0}"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse'+lampiran.group"
                :style="`background-color: ${lampiran.header_color}`"
              >
                {{ lampiran.group }}
              </button>
            </h2>
            <div :id="'collapse'+lampiran.group" class="accordion-collapse collapse" :class="{'show': listLampiranMcu.length === 0}" data-bs-parent="#accordionLampiran">
              <div v-if="lampiran.type === 'URL'" class="accordion-body">
                <div class="d-none">Untuk Trigger Update Konten = {{ parseHtmlResponse(lampiran.url_data.data, lampiran.group) }}</div>
                <div v-html="resultParseHtml[lampiran.group]"></div>
                <button
                  @click="downloadPdf(lampiran.url_data.pdf)"
                  class="caption btn btn-primary mt-3"
                >
                  {{ lampiran.url_data.pdf_label }}
                </button>
              </div>
              <div v-else class="accordion-body">
                <div v-for="detail in lampiran.detail" :key="detail.nama_pemeriksaan">
                  <table class="table table-responsive table-lampiran">
                    <tr>
                      <td style="width: 130px;" class="fs-12">Hasil Pemeriksaan</td>
                      <td>{{ detail.nama_pemeriksaan }}</td>
                    </tr>
                    <tr>
                      <td style="width: 130px;" class="fs-12">Nama Dokter</td>
                      <td>{{ detail.nama_dokter }}</td>
                    </tr>
                    <tr>
                      <td style="width: 130px;" class="fs-12">Hasil</td>
                      <td>
                        <span style="white-space: pre-line;" class="p-0">
                          {{ detail.hasil }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 130px;" class="fs-12">Kesimpulan</td>
                      <td>
                        <span style="white-space: pre-line;" class="p-0">
                          {{ detail.kesimpulan }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="detail.hasil_scan">
                      <td style="width: 130px;" class="fs-12">Lampiran</td>
                      <td>
                        <caption class="d-block">
                          <button @click="downloadHasilScan(detail.hasil_scan)" class="caption btn btn-primary">
                            Download Lampiran
                          </button>
                        </caption>
                      </td>
                    </tr>
                    <tr v-if="detail.pdf">
                      <td style="width: 130px;" class="fs-12">PDF</td>
                      <td>
                        <caption class="d-block">
                          <button @click="downloadPdf(detail.pdf)" class="caption btn btn-primary">
                            {{ detail.pdf_label }}
                          </button>
                        </caption>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modalDownload" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex flex-column align-items-center justify-content-center text-center">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <span class="fw-700 fs-18 lh-28 color-neutral-900 mt-md-3 mb-1">
                Pernyataan
              </span>
            </div>
          </div>
          <div class="desc-group mt-3 mt-md-0">
            <p class="fw-400 fs-14 lh-24 color-neutral-600" style="text-align: justify" v-html="!loading.setting && settingMessage" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-danger col py-2" data-bs-dismiss="modal">Tolak</button>
          <button type="button" class="btn btn-primary col py-2" data-bs-dismiss="modal" @click="prepareDonwload">Setuju</button>
        </div>
      </div>
    </div>
  </div>

  <!-- iframe trick -->
  <iframe ref="iframeDownload" src="" height="0" width="0"></iframe>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { nextTick, onMounted } from '@vue/runtime-core'
import { title } from '@/core/page'
import { settingMessage, getSetting } from '@/core/setting'
import { loading, getDetail, detailHasil, getLampiran, getLampiranMcu, listLampiran, listLampiranMcu, getDownloadLampiran, downloadLampiran, getPdfMcu, pdfMcu, confirmHasil } from '@/core/hasil'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { analytics } from '@/core/firebase-messaging'
import { showNotif } from '@/core/utility'
import axios from '@/plugins/axios'

export default {
  components: { Loading, Empty },
  setup () {
    const route = useRoute()
    const tabActive = ref(1)
    const resultParseHtml = ref({})
    const iframeDownload = ref(null)

    const onHandleDownload = async () => {
      await getSetting('notif_download')
    }

    const prepareDonwload = async () => {
      try {
        const newWindow = window.open()
        const result = await getDownloadLampiran(route.params.no_lab, route.params.id_cabang)
        if (result.status) {
          newWindow.location = downloadLampiran.value
        } else {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        console.error('error ambil data download lampiran', error)
      }
    }

    const downloadHasilScan = (url) => {
      const newWindow = window.open()
      newWindow.location = url
    }

    const downloadPdf = (url) => {
      iframeDownload.value.src = url
    }

    const parseHtmlResponse = (url, key) => {
      axios.get(url)
        .then(response => {
          resultParseHtml.value[key] = response.data
        }).catch(console.error)
    }

    onMounted(async () => {
      getDetail(route.params.no_lab, route.params.id_cabang)
      getLampiran(route.params.no_lab, route.params.id_cabang)
      getLampiranMcu(route.params.no_lab, route.params.id_cabang)
      getPdfMcu(route.params.no_lab, route.params.id_cabang)
      confirmHasil(route.params.id_cabang, route.params.no_lab)
      nextTick(() => {
        title.value = 'Hasil Lab'
      })

      analytics.logEvent('get_detail_hasil_plus_lampiran')
    })

    return {
      detailHasil,
      loading,
      tabActive,
      listLampiran,
      listLampiranMcu,
      downloadLampiran,
      downloadHasilScan,
      prepareDonwload,
      pdfMcu,
      onHandleDownload,
      settingMessage,
      parseHtmlResponse,
      resultParseHtml,
      downloadPdf,
      iframeDownload
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  cursor: pointer;
  font-weight: bold;
  color: #999;
  .col {
    &.active {
      color: black;
      border-bottom: 2px solid #8cc63f;
    }
  }
}

table {
    margin-bottom: 0;
}

.radius-12 {
  border-radius: 12px;
}

.table-lampiran {
  border-collapse: separate;
  border-spacing:0 10px;
}

.accordion-button {
  color: white;
  background-color: #8cc63f;
}

.accordion-button.mcu {
  color: white;
  background-color: #F79643;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
</style>

<style lang="scss" scoped>
// style nya app bar
nav {
  &:not(.sub) {
    padding-top: 2%;
  }

  .appbar {
    position: relative;
    background-color: white;

    .btn-search {
      position: absolute;
      right: 0;
    }

    @media (max-width: 520px) {
      z-index: 99;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 11px 18px;
      border-bottom: 1px solid rgb(240, 240, 240);
    }

    h1 {
      @media (max-width: 520px) {
        margin: auto;
      }
    }

    @media (max-width: 520px) {
      .btn-back {
        position: absolute;
      }

      .btn-search {
        position: absolute;
        right: .5rem;
      }

      .nav-search {
        position: absolute;
        left: .7rem;
        right: 4rem;
        top: 50%;
        transform: translateY(-50%);

        input {
          padding: .7rem 1rem;
        }
      }
    }
  }
}

ul.clean {
  li {
    list-style-type: none;
  }
}
</style>
